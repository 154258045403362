import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteColor {
    placeholder?: string;
  }
  interface SimplePaletteColorOptions {
    placeholder?: string;
  }
}

let theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000",
    },
    primary: {
      light: "#ff8049",
      main: "#e94e1b",
      dark: "#af1200",
      contrastText: "#fff",
      placeholder: "rgba(233, 78, 27, 0.1)",
    },
    secondary: {
      light: "#000",
      main: "#000",
      dark: "#000",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: '"acumin-pro-extra-condensed", sans-serif',
    fontSize: 20,
    h1: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    h2: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    h3: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    h4: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    h5: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    h6: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    subtitle1: {
      fontFamily: '"Bebas Neue", sans-serif',
      fontSize: "1.2rem",
    },
    subtitle2: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    body1: {
      fontFamily: '"acumin-pro-extra-condensed", sans-serif',
    },
    body2: {
      fontFamily: '"acumin-pro-extra-condensed", sans-serif',
    },
    button: {
      fontFamily: '"Bebas Neue", sans-serif',
    },
    caption: {
      fontFamily: '"acumin-pro-extra-condensed", sans-serif',
      fontSize: "1.2rem",
    },
    overline: {
      fontFamily: '"acumin-pro-extra-condensed", sans-serif',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollbarColor: "#e94e1b #555",
          scrollbarWidth: "thin",
          scrollbarGutter: "stable",
        },
        body: {
          marginBottom: 0,
          paddingBottom: "100px",
        },
        a: {
          color: "white",
          textDecoration: "unset",
          "&:hover": {
            color: "#e94e1b",
            textDecoration: "unset",
          },
        },
        "&::-webkit-scrollbar": {
          height: "0.4em",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#555",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#e94e1b",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#af1200",
        },
        "div:has(> .gm-tilt)": {
          display: "none !important",
        },
        // ".gm-tilt:parent": {
        //   display: "none !important",
        // },
        ".markerLabel": {
          fontFamily: '"acumin-pro-extra-condensed", sans-serif',
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          lineHeight: 1,
          padding: 3,
          paddingTop: 0,
        },
        ".SnackbarContainer-root": {
          zIndex: "2000 !important",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#000",
          backgroundImage: "unset",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorInfo: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(16, 16, 16, 1)",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: "#000",
          paddingBottom: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderBottom: "unset",
          "&:before": {
            display: "none",
          },
          backgroundColor: "unset",
          backgroundImage: "unset",
          "&.FlatroofGroundPanelGroupOptions": {
            backgroundColor: "#000",
            "& .MuiAccordionSummary-root": {
              paddingBottom: 8,
            },
            "& .MuiAccordionDetails-root": {
              // The default styles for MuiAccordionDetails
              backgroundColor: "rgba(16, 16, 16, 1)",
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "unset",
          margin: 0,
          padding: 0,
          "&.componentAccordionSummary": {
            backgroundColor: "#000",
          },
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        // root: {
        //   backgroundColor: "unset",
        //   backgroundImage: "unset",
        // },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "white",
          textDecoration: "unset",
          transition: "color 0.1s linear",
          "& h6": {
            transition: "color 0.1s linear",
          },
          "&:hover": {
            color: "#e94e1b",
            textDecoration: "unset",
            "& .MuiIconButton-root": {
              backgroundColor: "#af1200",
            },
          },
          "&.disabled": {
            color: "#757575",
            cursor: "default",
            "& .MuiIconButton-root": {
              backgroundColor: "#757575",
            },
          },
          "& .MuiIconButton-root": {
            transition: "background-color 0.1s linear",
            backgroundColor: "#e94e1b",
            "&:hover": {
              backgroundColor: "#af1200",
            },
            "& .Mui-disabled": {
              backgroundColor: "#757575",
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "unset",
          backgroundImage: "unset",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&.stickyTableCell": {
            zIndex: 1001,
            backgroundColor: "#1c1c1c",
          },
        },
        head: {
          fontFamily: '"Bebas Neue", sans-serif',
        },
        footer: {
          color: "white",
          fontFamily: '"Bebas Neue", sans-serif',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #000 inset !important",
            backgroundColor: "#000 !important",
            backgroundClip: "content-box !important",
          },
        },
        sizeSmall: {
          input: {
            paddingTop: 0,
            paddingBottom: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-inputSizeSmall": {
            paddingTop: 0,
            paddingBottom: 1,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          paddingTop: 12,
        },
        root: {
          "&.RAULI-alert": {
            color: "#fff !important",
            backgroundColor: "#e94e1b !important",
            "& .MuiAlert-icon": {
              display: "none",
            },
            "& .MuiTableCell-root": {
              color: "#fff !important",
              borderBottomColor: "#fff !important",
              padding: 0,
              paddingRight: 8,
            },
          },
          "&.transparent-alert": {
            color: "#fff !important",
            backgroundColor: "transparent !important",
            "& .MuiAlert-icon": {
              display: "none",
            },
            "& .MuiTableCell-root": {
              color: "#fff !important",
              borderBottomColor: "#fff !important",
              padding: 0,
              paddingRight: 8,
            },
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          lineHeight: 0.8,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginLeft: 4,
          padding: 4,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#000",
          fontFamily: '"Bebas Neue", sans-serif',
          lineHeight: 1,
          paddingTop: 8,
          paddingBottom: 5,
        },
        arrow: {
          color: "#000",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& legend": {
            fontFamily: '"Bebas Neue", sans-serif',
          },
          // "& input::placeholder": {
          //   fontFamily: '"Bebas Neue", sans-serif',
          // },
        },
      },
      // defaultProps: {
      //   InputLabelProps: {
      //     style: {
      //       fontFamily: '"Bebas Neue", sans-serif',
      //     },
      //   },
      // },
    },
    MuiInputLabel: {
      styleOverrides: {
        sizeSmall: {
          marginTop: -3,
          lineHeight: 1,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            color: "#e94e1b !important",
            backgroundColor: "rgb(233, 78, 27, 0.16) !important",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          transition: "background-color 0.1s linear",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#e94e1b !important",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          transition: "color 0.1s linear",
          "&:hover": {
            color: "#e94e1b",
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
