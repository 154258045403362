import { Logger } from "tslog";
import config from "../config/getConfig";

const logger = new Logger({
  type: config.environment !== "development" ? "json" : "pretty",
  hideLogPositionForProduction: config.environment === "production",
  minLevel: config.environment !== "development" ? 3 : 0,
});

export default logger;
