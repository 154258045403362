import { doc, getDoc, setDoc } from "firebase/firestore";
import { IUser } from "../storage/User.interfaces";
import { IDistributor } from "./distributor.interfaces";
import db, { auth } from "./firebase";

export const getUserData = async (uid: string): Promise<IUser> => {
  const userDoc = await getDoc(doc(db, "users", uid));
  const tokenResult = await auth.currentUser?.getIdTokenResult();
  if (userDoc.exists()) {
    return {
      distributor: "",
      disable_production_estimate: false,
      disable_electrical_design: false,
      admin: tokenResult?.claims?.admin ?? null,
      ...userDoc.data(),
      uid: uid,
    } as IUser;
  } else {
    throw "user data not found!";
  }
};

export const setUserData = async (user: Omit<IUser, "admin">) => {
  if (user.uid === undefined) {
    throw "user.uid is undefined!";
  }

  return setDoc(
    doc(db, "users", user.uid),
    {
      distributor: user.distributor,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      company: user.company,
      country: user.country,
      phonenumber: user.phonenumber,
      disable_production_estimate: user.disable_production_estimate ?? false,
      disable_electrical_design: user.disable_electrical_design ?? false,
    },
    {
      merge: true,
    }
  );
};

export const getDistributor = async (id: string): Promise<IDistributor> => {
  const distributorDoc = await getDoc(doc(db, "distributors", id));
  if (distributorDoc.exists()) {
    const distributor = { ...(distributorDoc.data() as IDistributor) };
    return {
      id: id,
      name: distributor.name,
      webshop: distributor.webshop ?? {
        url: "",
        apiEndpoint: "",
        pitched: false,
        wall: false,
        flat: false,
      },
      overrides: distributor.overrides ?? {
        rails: {},
      },
    };
  } else {
    throw "distributor not found!";
  }
};
