import { autorun, makeObservable, observable, runInAction } from "mobx";
import logger from "../utils/logger";
import {
  BeforeInstallPromptEvent,
  ICommonStorage,
  TUpdateState,
} from "./Common.interfaces";

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

class CommonStorage implements ICommonStorage {
  loaded = false;
  updateAvailable: TUpdateState = "no-update";
  online = true;
  canInstallApp: BeforeInstallPromptEvent | null = null;
  persistentStorageAvailable = true;
  unsavedChanges = 0;
  isIOS = false;

  constructor() {
    makeObservable(this, {
      loaded: observable,
      updateAvailable: observable,
      online: observable,
      canInstallApp: observable,
      persistentStorageAvailable: observable,
      unsavedChanges: observable,
      isIOS: observable,
    });

    this.checkOnline();

    if (window.navigator.serviceWorker) {
      window.navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration?.installing) {
          this.setUpdateState("update-in-progress");
        } else if (registration?.waiting) {
          this.setUpdateState("update-available");
        } else if (registration?.active) {
          this.setUpdateState("no-update");
        }

        // Check updates periodically
        setInterval(() => {
          registration?.update();
        }, 900000);

        registration?.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          newWorker?.addEventListener("statechange", () => {
            const state = newWorker.state;
            switch (state) {
              case "installed":
                this.setUpdateState("update-available");
                break;
              case "activated":
                if (this.updateAvailable === "update-approved") {
                  window.location.reload();
                }
                this.setUpdateState("no-update");
                break;
              default:
                break;
            }
          });
        });
      });
    }

    autorun(() => {
      if (this.updateAvailable === "update-approved") {
        window.navigator.serviceWorker
          .getRegistration()
          .then((registration) => {
            registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
          });
      }
    });

    window.addEventListener("online", () => this.checkOnline());
    window.addEventListener("offline", () => this.checkOnline());
    window.addEventListener(
      "beforeunload",
      (event) => {
        if (this.unsavedChanges > 0) {
          event.preventDefault();
          return (event.returnValue = "You have unsaved changes!");
        }
        return event;
      },
      { capture: true }
    );

    window.addEventListener(
      "beforeinstallprompt",
      (event: BeforeInstallPromptEvent) => {
        event.preventDefault();
        logger.info("App can be installed!");
        runInAction(() => {
          this.canInstallApp = event;
        });
      }
    );

    autorun(() => {
      if (this.unsavedChanges > 1) {
        logger.info(`There is ${this.unsavedChanges} unsaved change!`);
      } else if (this.unsavedChanges > 2) {
        logger.info(`There are ${this.unsavedChanges} unsaved changes!`);
      } else {
        logger.info("There are no unsaved changes!");
      }
    });

    if (isIOS()) {
      runInAction(() => {
        this.isIOS = true;
      });
    }

    runInAction(() => {
      this.loaded = true;
    });
  }

  setUpdateState = (state: TUpdateState) => {
    runInAction(() => {
      this.updateAvailable = state;
    });
  };

  checkOnline = () => {
    if (window.navigator && window.navigator.onLine !== undefined) {
      if (window.navigator.onLine) {
        this.setOnline();
      } else {
        this.setOffline();
      }
    } else {
      this.setOnline();
    }
  };

  setOnline = () => {
    logger.info("Connection is back online.");
    runInAction(() => (this.online = true));
  };

  setOffline = () => {
    logger.info("Connection is now offline.");
    runInAction(() => (this.online = false));
  };

  clearInstallApp = () => {
    runInAction(() => (this.canInstallApp = null));
  };

  setPersistentStorageAvailable = () => {
    runInAction(() => (this.persistentStorageAvailable = true));
  };

  clearPersistentStorageAvailable = () => {
    runInAction(() => (this.persistentStorageAvailable = false));
  };

  increaseUnsavedChanges = () => {
    runInAction(() => this.unsavedChanges++);
  };

  decreaseUnsavedChanges = () => {
    runInAction(() => this.unsavedChanges--);
  };
}

const commonStorage = new CommonStorage();
export default commonStorage;
