import {
  QuerySnapshot,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { DateTime } from "luxon";
import logger from "../utils/logger";
import db from "./firebase";
import { INotification } from "./notification.interfaces";

export const getNotifications = async (): Promise<INotification[]> => {
  const q = query(collection(db, "notification"));
  const notifications: INotification[] = [];
  await getDocs(q)
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        const notification = doc.data();
        notifications.push({
          ...(notification as INotification),
          id: doc.id,
          date: DateTime.fromISO(notification.date),
        });
      });
    })
    .catch((error) => logger.error("Error fetching notifications", error));
  notifications.sort((a, b) => (a.date < b.date ? -1 : 1));
  return notifications;
};

export const listenForNotificationChanges = (
  callback: (snapshot: QuerySnapshot) => void
) => {
  const q = query(collection(db, "notification"), orderBy("date", "desc"));
  return onSnapshot(q, (querySnapshot) => callback(querySnapshot));
};
