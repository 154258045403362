import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import cs_admin from "./cs/admin.json";
import cs_components from "./cs/components.json";
import cs_default from "./cs/default.json";
import cs_lead from "./cs/lead.json";
import cs_productCard from "./cs/productCard.json";
import de_admin from "./de/admin.json";
import de_components from "./de/components.json";
import de_default from "./de/default.json";
import de_lead from "./de/lead.json";
import de_productCard from "./de/productCard.json";
import en_admin from "./en/admin.json";
import en_components from "./en/components.json";
import en_default from "./en/default.json";
import en_lead from "./en/lead.json";
import en_productCard from "./en/productCard.json";
import fi_admin from "./fi/admin.json";
import fi_components from "./fi/components.json";
import fi_default from "./fi/default.json";
import fi_lead from "./fi/lead.json";
import fi_productCard from "./fi/productCard.json";
import hu_admin from "./hu/admin.json";
import hu_components from "./hu/components.json";
import hu_default from "./hu/default.json";
import hu_lead from "./hu/lead.json";
import hu_productCard from "./hu/productCard.json";
import { FALLBACK_LANGUAGE } from "./i18n.interfaces";
import ro_admin from "./ro/admin.json";
import ro_components from "./ro/components.json";
import ro_default from "./ro/default.json";
import ro_lead from "./ro/lead.json";
import ro_productCard from "./ro/productCard.json";
import sv_admin from "./sv/admin.json";
import sv_components from "./sv/components.json";
import sv_default from "./sv/default.json";
import sv_lead from "./sv/lead.json";
import sv_productCard from "./sv/productCard.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    defaultNS: "default",
    resources: {
      en: {
        default: en_default,
        components: en_components,
        admin: en_admin,
        productCard: en_productCard,
        lead: en_lead,
      },
      fi: {
        default: fi_default,
        components: fi_components,
        admin: fi_admin,
        productCard: fi_productCard,
        lead: fi_lead,
      },
      de: {
        default: de_default,
        components: de_components,
        admin: de_admin,
        productCard: de_productCard,
        lead: de_lead,
      },
      sv: {
        default: sv_default,
        components: sv_components,
        admin: sv_admin,
        productCard: sv_productCard,
        lead: sv_lead,
      },
      cs: {
        default: cs_default,
        components: cs_components,
        admin: cs_admin,
        productCard: cs_productCard,
        lead: cs_lead,
      },
      hu: {
        default: hu_default,
        components: hu_components,
        admin: hu_admin,
        productCard: hu_productCard,
        lead: hu_lead,
      },
      ro: {
        default: ro_default,
        components: ro_components,
        admin: ro_admin,
        productCard: ro_productCard,
        lead: ro_lead,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      lookupCookie: "rauliLng",
      lookupLocalStorage: "rauliLng",
      lookupSessionStorage: "rauliLng",
    },
  });

if (process.env.NODE_ENV === "development") {
  // Add changeLanguage method to window when testing, as calling it's a lot faster to change this way
  // instead of setting localStorage and reloading page
  // @ts-expect-error TS doesn't like setting random functions to window...
  window.changeLanguage = i18n.changeLanguage;
}

export default i18n;
