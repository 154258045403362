import * as Sentry from "@sentry/react";
import config from "./config/getConfig";
import logger from "./utils/logger";
import "./wdyr";

if (config.environment !== "development") {
  Sentry.init({
    dsn: "https://0bf19540a4a2036a8cfb9db6b763fc1c@o4507657470148608.ingest.de.sentry.io/4508840645492816",
    release: `rauli@${config.version}`,
    environment: config.environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/app\.raulibrackets\.fi\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });

  logger.attachTransport((logObj) => {
    const logLevel = logObj._meta.logLevelName.toLowerCase();
    if (["error", "fatal"].includes(logLevel)) {
      const message = Object.keys(logObj)
        .filter((key) => key !== "_meta")
        .map((key) => `${logObj[key]}`)
        .join("\n");
      Sentry.captureMessage(message, logLevel as Sentry.SeverityLevel);
    }
  });
}

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import commonStorage from "./storage/Common";
import "./translations/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: () => {
    commonStorage.setUpdateState("update-available");
  },
  onSuccess: () => {
    commonStorage.setUpdateState("no-update");
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
