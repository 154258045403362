import { onAuthStateChanged } from "firebase/auth";
import { autorun, makeObservable, observable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { IAdminPrivileges } from "../firebase/admin.interfaces";
import auth, {
  loginWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  sendVerification,
} from "../firebase/auth";
import { IDistributor } from "../firebase/distributor.interfaces";
import { getDistributor, getUserData, setUserData } from "../firebase/user";
import logger from "../utils/logger";
import { IRegistrationUser, IUser, IUserStorage } from "./User.interfaces";

class UserStorage implements IUserStorage {
  uid: string | undefined = undefined;
  distributor = "";
  email = "";
  firstname = "";
  lastname = "";
  company = "";
  country = "";
  phonenumber = "";
  email_verified = false;
  disable_production_estimate = false;
  disable_electrical_design = false;
  distributorInfo: IDistributor | null = null;
  admin: IAdminPrivileges | null = null;

  constructor() {
    makeObservable(this, {
      uid: observable,
      distributor: observable,
      email: observable,
      firstname: observable,
      lastname: observable,
      company: observable,
      country: observable,
      phonenumber: observable,
      email_verified: observable,
      disable_production_estimate: observable,
      disable_electrical_design: observable,
      distributorInfo: observable,
      admin: observable,
    });

    makePersistable(this, {
      name: "RauliUserStore",
      properties: [
        "uid",
        "distributor",
        "email",
        "firstname",
        "lastname",
        "company",
        "country",
        "phonenumber",
        "email_verified",
        "admin",
        "disable_production_estimate",
        "disable_electrical_design",
      ],
      storage: window.localStorage,
      // expireIn: 86400000, // One day in millsesconds
      // removeOnExpiration: true,
      stringify: true,
      // debugMode: true,
    }).then(() => {
      this.linkToDistributor();
    });

    autorun(() => {
      if (this.distributor) {
        getDistributor(this.distributor)
          .then((distributor) => {
            runInAction(() => {
              this.distributorInfo = distributor;
            });
          })
          .catch((error) => {
            logger.error("Distributor not found", error);
            runInAction(() => {
              this.distributorInfo = null;
            });
          });
      } else {
        runInAction(() => {
          this.distributorInfo = null;
        });
      }
    });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        runInAction(() => {
          this.uid = user.uid;
          this.email_verified = user.emailVerified;
        });
        this.getUserData(user.uid);
      } else {
        this.reset();
      }
    });
  }

  reset = () => {
    runInAction(() => {
      this.distributor = this.uid === undefined ? this.distributor : "";
      this.uid = undefined;
      this.email = "";
      this.firstname = "";
      this.lastname = "";
      this.company = "";
      this.country = "";
      this.phonenumber = "";
      this.email_verified = false;
      this.disable_production_estimate = false;
      this.disable_electrical_design = false;
      this.admin = null;
    });
  };

  login = async (email: string, password: string) => {
    const user = await loginWithEmailAndPassword(email, password);
    runInAction(() => {
      this.email = email;
    });
    runInAction(() => {
      this.email_verified = user.emailVerified;
    });
    const distributor = this.distributor;
    const userdata = await this.getUserData(user.uid);
    // If distributor is set (via link), and current user doesn't have a distributor set
    // update distributor.
    if (distributor && !userdata.distributor) {
      this.update({ distributor: distributor });
    }
    return userdata;
  };

  logout = async () => {
    await logout();
  };

  register = async (user: Omit<IRegistrationUser, "admin">) => {
    const registeredUser = await registerWithEmailAndPassword(
      user.email,
      user.password
    );
    const registeredUserData = {
      ...user,
      uid: registeredUser.uid,
    };
    await setUserData(registeredUserData);
    runInAction(() => {
      this.email_verified = registeredUser.emailVerified;
    });
    if (!registeredUser.emailVerified) {
      sendVerification(registeredUser);
    }
    return this.getUserData();
  };

  update = async (user: Partial<IUser>) => {
    if (this.uid === undefined) {
      throw "uid is not defined!";
    }
    const userData = {
      distributor: this.distributor,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      company: this.company,
      country: this.country,
      phonenumber: this.phonenumber,
      email_verified: this.email_verified,
      disable_production_estimate: this.disable_production_estimate,
      disable_electrical_design: this.disable_electrical_design,
      ...user,
      uid: this.uid,
    };
    await setUserData(userData);
    return this.getUserData();
  };

  getUserData = async (uid?: string) => {
    if (uid === undefined) {
      uid = this.uid;
    }
    if (uid === undefined) {
      throw "uid is not defined!";
    }
    try {
      const userData = await getUserData(uid);
      if (userData) {
        runInAction(() => {
          this.distributor = userData.distributor;
          this.email = userData.email;
          this.firstname = userData.firstname;
          this.lastname = userData.lastname;
          this.company = userData.company;
          this.country = userData.country;
          this.phonenumber = userData.phonenumber;
          this.disable_production_estimate =
            userData.disable_production_estimate;
          this.disable_electrical_design =
            userData.disable_electrical_design ?? false;
          this.admin = userData.admin;
        });
      }
    } catch (error) {
      logger.error("Auth: Fetching user data failed.", error);
    }

    return {
      uid: uid,
      distributor: this.distributor,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      company: this.company,
      country: this.country,
      phonenumber: this.phonenumber,
      email_verified: this.email_verified,
      disable_production_estimate: this.disable_production_estimate,
      disable_electrical_design: this.disable_electrical_design,
      admin: this.admin,
    };
  };

  linkToDistributor = () => {
    const searchParams = new URL(window.location.href).searchParams;
    const distributor = searchParams.get("distributor");

    if (distributor) {
      if (!this.uid) {
        // If user hasn't logged in yet, set distributor to the one from link.
        // Used later to send it via registration as well as in login callback.
        runInAction(() => {
          this.distributor = distributor;
        });
      } else if (this.uid && !this.distributor) {
        // Otherwise if user has logged in, doesn't have distributor set,
        // update the distributor information.
        this.update({
          distributor: distributor,
        });
      }
    }
  };
}

const userStorage = new UserStorage();
export default userStorage;
